import { Navbar } from '../components/Navbar'
import { graphql } from 'gatsby'
import { Footer } from '../components/Footer'
import { Link } from 'gatsby-plugin-react-i18next'
import { compareDesc, intlFormat } from 'date-fns'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Fragment } from 'react'

const Blog = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map((e) => e.node.frontmatter)

  const ordered = posts.sort((a, b) =>
    compareDesc(new Date(a.date), new Date(b.date))
  )

  const featuredPosts = ordered.slice(0, 4)
  const olderPosts = ordered.slice(4, ordered.length)

  return (
    <div className='flex flex-col relative'>
      <div className='flex flex-col min-h-screen '>
        <Navbar />
        <div className='flex flex-col items-center justify-center h-56 bg-neutral-100 '>
          <h1
            id='logo'
            className='pb-3 text-8xl md:text-9xl font-header font-extrabold z-10 bg-gradient-to-r from-brand to-auxiliary'
          >
            B<span>lo</span>g
          </h1>
        </div>
        <main className='bg-white rounded-t-lg container font-text py-6 flex-grow leading-relaxed'>
          <h1 className='text-center mb-3 font-header leading-relaxed text-2xl font-extrabold text-gradient  bg-gradient-to-r from-brand to-auxiliary'>
            Posts
          </h1>
          <div className='flex flex-col sm:grid gap-4 grid-row-3 grid-cols-2 max-w-screen-xl  mx-auto'>
            {featuredPosts.map((p, index) => {
              const isMain = index === 0

              return (
                <Fragment key={p.slug}>
                  <div
                    className={`
                    flex gap-2 group
                    ${isMain ? 'gap-2 row-span-3 flex-col' : ''}
                  `}
                  >
                    <Link
                      to={`/blog/${p.slug}`}
                      className={`flex ${isMain ? 'w-full' : 'max-w-[35%]'}`}
                    >
                      <GatsbyImage alt={p.title} image={getImage(p.image)} />
                    </Link>
                    <div className='flex-grow'>
                      <p className='font-sub text-sm lg:text-md mb-1'>
                        {intlFormat(
                          new Date(p.date),
                          {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric'
                          },
                          { locale: p.lang }
                        )}
                      </p>
                      <Link
                        to={`/blog/${p.slug}`}
                        className='font-text font-bold text-md lg:text-lg group-hover:text-gradient group-hover:bg-gradient-to-r group-hover:from-brand group-hover:to-auxiliary'
                      >
                        {p.title}
                      </Link>

                      {isMain && (
                        <p className='font-text mt-2'>{p.description}</p>
                      )}
                    </div>
                  </div>
                  {index < featuredPosts.length - 1 && (
                    <div className='sm:hidden h-px bg-neutral-200' />
                  )}
                </Fragment>
              )
            })}
          </div>
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default Blog

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allMarkdownRemark(filter: { frontmatter: { lang: { eq: $language } } }) {
      edges {
        node {
          frontmatter {
            title
            lang
            description
            slug
            date
            image {
              childImageSharp {
                id
                gatsbyImageData(
                  aspectRatio: 1.777778
                  transformOptions: { fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  }
`
